import React from "react";
import { Wrapper } from "../components/Wrapper";
import { Boxes } from "../components/Boxes";
import { Footer } from "../components/Footer";
import { PreFooter } from "../components/PreFooter";

const imgs = [
	'/services-banners-01.png',
	'/services-banners-02.png',
	'/services-banners-03.png',
	'/services-banners-04.png'
];

function randomImage() {
	return imgs[Math.floor(Math.random() * imgs.length)];
}

function downloadLink(event) {
	event.preventDefault();
	console.log('select', event.target);
	let url = event.target.value;
	const link = document.createElement('a');
	if (url.slice(0, 4) === 'http') {
		link.href = url;
	} else {
		link.href = './downloads/' + url;
		link.setAttribute(
			'download',
			url,
		);
	}
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

const CES = () => {

	return (
		<>
			<Wrapper backgroundColor="white" paddingBottom="0px" color="#333">
				<div style={{padding: '20px', paddingBottom: 0}}>
					<h1>Crossroads for Educational Services, Inc</h1>
					<p style={{color: '#333'}}>
						As the CEO of Crossroads Educational Services, I personally guarantee the highest 
						quality in our evaluations, consultations, advocacy, professional development as 
						well as academic therapy. Everything we do incorporates our incredible passion for 
						the special needs student – especially the dyslexic. It is this passion that pushes 
						us toward making sure that every child who is within a K-12 environment receives the 
						help that they need. Our approach to literacy training has eliminated children’s 
						anxiety toward reading and enabled them to excel in all subject areas of academia.</p>
				</div>
				<div style={{height: '100%', flex: '0 0 auto'}}>
					<img className="ces-banner-image" src={randomImage()} alt="service banner"/>
				</div>
			</Wrapper>
			<Wrapper backgroundColor="#333" paddingBottom="20px" color="white">
				<div style={{padding: '0 20px'}}>
				<h1 style={{marginBottom: 0}}>Downloads</h1>
				<select id="downloads" onChange={downloadLink}>
                    <option value="">-- Select Download --</option>
                    <option value="https://itunes.apple.com/us/app/flashcard-learning/id1370158168?mt=8">FLASHCARD - RECIPE FOR READING</option>
                    <option value="paragraphs_and_games.doc" download="">PARAGRAPHS AND GAMES</option>
                    <option value="recipe_for_reading.ppt" download="">RECIPE FOR READING</option>
                    <option value="RR-LABELS.doc" download="">RR LABELS</option>
                    <option value="RR-PHRASES-1.doc" download="">RR PHRASES</option>
                    <option value="RR-SENTENCES-1.doc" download="">RR SENTENCES</option>
                    <option value="RR-SENTENCES-2a.doc" download="">RR SENTENCES 2</option>
                    <option value="RR-WORDS.doc" download="">RR WORDS</option>
                    <option value="RR-WORDS-2.doc" download="">RR WORDS 2</option>
                    <option value="RR-WORD-SEARCH.docx" download="">RR WORD SEARCH</option>
                    <option value="RR-123.ppt" download="">RECIPE FOR READING SECTION 1, 2, &amp; 3</option>
                    <option value="RR-45.ppt" download="">RECIPE FOR READING SECTION 4 &amp; 5</option>
                    <option value="SYLLABLE.docx" download="">SYLLABLE</option>
                </select>
				</div>
			</Wrapper>

			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>Recipe for Reading / Reading Recipe</h2>
					<p>Intervention Strategies for Struggling Readers. An Orton-Gillingham Based Program: 
						This workshop/training contains a comprehensive, multisensory, phonic-based reading 
						program which presents a skill sequence and lesson structure designed for beginning, 
						at-risk or struggling readers. Recipe for Reading / Reading Recipe can be used as a 
						successful intervention for students in Tier 1 or can be the primary, beginning reading 
						program for Tier 2 and 3 students as well as special education reading programs. Please 
						contact Crossroads for Educational Services for training.</p>
				</div>
				<div>
					<h2>Professional Workshops</h2>
					<p>Crossroads offer professional workshops for teachers, administrators and parents. These 
						workshops have the central theme of dyslexia. The following questions are answered during 
						our workshops: How does a child qualify as a dyslexic? How is a child remediated with dyslexia? 
						How does a district provide appropriate programming for dyslexic children within a public school? 
						How do I, a parent, know that my child has the right program?</p>
				</div>
			</Wrapper>

			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>Evaluation</h2>
					<p>Crossroads offer a comprehensive testing battery which is a compilation of standardized 
						tests that allow us to establish an accurate picture of each individual’s ability/disability. 
						Our testing battery is specific to a student’s reading disorder especially dyslexia. 
						A full analysis , consultation and report is part of every evaluation.</p>
				</div>
				<div>
					<h2>Professional Workshops</h2>
					<p>Crossroads offer professional workshops for teachers, administrators and parents. These 
						workshops have the central theme of dyslexia. The following questions are answered during 
						our workshops: How does a child qualify as a dyslexic? How is a child remediated with dyslexia? 
						How does a district provide appropriate programming for dyslexic children within a public school? 
						How do I, a parent, know that my child has the right program?</p>
				</div>
			</Wrapper>

			
			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start" style={{borderBottom: '4px solid var(--color-teal) !important'}}>
				<div>
					<h2>Writen Language</h2>
					<p>Crossroads offer written language comprehension programs for those who struggle with comprehension and written language skill deficits.</p>
					<b><a href="https://www.NPRinc.com">www.NPRinc.com</a></b> <i>(RTI Guide: Making It Work)</i><br/>
					<b><a href="https://www.epsbooks.com">www.epsbooks.com</a></b> <i>(Recipe for Reading manual and workbooks)</i>
				</div>
				<div>
					<h2>Decoding/Encoding</h2>
					<p>Crossroads provides intensive one-to-one instruction that
					focuses on the five pillars of reading while developing decoding, encoding
					and comprehension skills. The pillars include Phonological and Phonemic
					Awareness, Phonics, Vocabulary, Spelling, Fluency and Comprehension</p>
				</div>
				
			</Wrapper> 
			<Boxes></Boxes>
			<PreFooter/>
			<Footer></Footer>
		</>
	);
};

export default CES;
