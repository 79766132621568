import React from 'react';
import config from '../../config.json';

const DATE = new Date();
const YEAR = DATE.getFullYear();

export const Footer = () => {
	return (
		<div className="bottom-footer">
			<div> &#169; {YEAR} {config.site}<span>&nbsp;&nbsp;&#8226;</span>&nbsp;&nbsp;</div>
			<div>{config.name}<span>&nbsp;&nbsp;&#8226;</span>&nbsp;&nbsp;</div>
			<a href={config.phone}>{config.phone}</a>
		</div>
	)
}