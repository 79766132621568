import React, { useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { Boxes } from "../components/Boxes";
import { PreFooter } from "../components/PreFooter";
import { Footer } from "../components/Footer";

const RRR = () => {

	const [displayMore, setDisplayMore] = useState('none');
	const [btn, setButton] = useState('block');

	const showMoreFunction = (event) => {
		if (displayMore === 'none') {
			setDisplayMore('flex');
			setButton('none');
		} else {
			setDisplayMore('none');
			setButton('inline-block');
		}
	}

	return (
		<>
		<Wrapper backgroundColor="white" paddingButtom="40px" color="#333">
			<div style={{padding: '20px'}}>
				<h1 className="main-header">Rapid Reading Program</h1>
				<p style={{color: '#333'}}>
				The ultimate goal of my 40-year career has always been to serve the students and their parents with the best of my abilities, and to make sure that every child leaves my practice reading well. Watching the children succeed and feeling their enthusiasm towards books is the most gratifying part of my work. Because of this encouragement, I have expanded my practice to offering office and virtual services to many more students across Long Island. Services are offered year round.</p>
			</div>
			<div className='crusso-image-about'>
				<img src='/dr-concetta-russo-photo.png' height="100%" alt="Dr Russo" loading="lazy" style={{position: 'relative', bottom: '-7px'}}/>
			</div>
		</Wrapper>

		<Wrapper alignItems="start" backgroundColor="var(--color-light)" color="var(--color-dark)" className="rrr-blurb">
			<>
			<div className="blurb">
				<img className="quote-top" alt="quotes" src='/quotes.png'/>
				<p>Our son is 11 years old and I did not notice till this age that he was having trouble reading and spelling. His school never said that there was a problem just that all kids learn at a different pace. I could not accept that. We sought out help to figure out why. We found Dr. Russo and learned that he has dyslexia. Not understanding, she explained exactly what was going on with our son and immediately started to work with him. Within 2 months I had already noticed difference s in my sons reading. He was more confident when he read and there were less stop- and go when he read a sentence. He was able to pronounce words better than before and does not give up when he is not sure of the word. We are so happy that we found Dr. Russo and would recommend her to others.</p>
				<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
			</div>

			<div className="blurb">
				<img className="quote-top" alt="quotes" src='/quotes.png'/>
				<p>Our son is a brilliant 10 year boy who has been struggling academically for all of his school years. After years of frustration of not knowing exactly what our son’s problem is, we has the blessing and fortune to learn of Dr. Russo.</p>
				<p>After one phone call Dr. Russo without hesitation set up an appointment to meet and evaluate my son. This evaluation turned out to be one of the most thorough evaluations ever done for our boy. And yes, there were several!</p>
				<p>Dr. Russo has a gift of not only being patient with a child who has special needs. She is incredibly brilliant in her findings, understanding and the “know how” to teach our son how to learn not only how to read and write with his dyslexia issues. But also how to train his brain in a beautiful w3ay for our son to understand, respect and appreciate who he is and how special a child such as he actually is.</p>
				<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
			</div>

			<div className="blurb">
				<img className="quote-top" alt="quotes" src='/quotes.png'/>
				<p>Dr. Russo has a gift that allows her to see how she can have a positive effect on our son and bring reading and writing up to par with his high intelligence.</p>
				<p>It has only been three months since we have been blessed with Dr. Russo in our lives, however, I can 100% say I see a tremendous growth with our son not only academically but also with his confidence. Our son looks forward to learning from Dr. Russo every week, 2 days a week.</p>
				<p>After meeting Dr. Russo and spending some time with her I must say, I finally exhaled!! As a mom that is priceless. I am made to feel that our boy will be perfectly fine. My husband and I both know we are in great hands. Dr. Russo gives all of herself to our sons tutoring time with the utmost professionalism and friend.</p>
				<p>Simply put, I don’t know where I would be without Dr. Russo. This is Dr. Russo’s CALLING indeed.</p>
				<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
			</div>

			<main style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
				<button style={{fontSize: '14pt', marginBottom: '20px', display: btn}} className="blue-button" onClick={(showMoreFunction)}>Read More</button>
			</main>

			<div style={{display: displayMore, flexDirection: 'column'}}>

				<div className="blurb">
					<img className="quote-top" alt="quotes" src='/quotes.png'/>
					<p>My son has been struggling with reading for 2 years and as a mother my heart was broken to see him struggle and his confidence decline. I have tried many different available options to get my son reading help and nothing worked. There were many nights that I have cried and wondered why nothing was working for my son. I was constantly on the search for help until I came across Dr. Russo.</p>
					<p>I spoke with her on the phone and she answered each and every one of my concerns. Dr. Russo is so knowledgeable when it comes to children with dyslexia. My son started working with her and within two weeks I could see such progress with him such as his confidence was back, he couldn’t wait to work with her again and he was reading. My son finally felt secure enough with reading that he asked me to buy him books!!!</p>
					<p>Wow as a mom I was waiting for this moment. My son loves Dr. Russo because of her amazing personality and how kind she is to him that he asks me to bring him back to see her. Dr. Russo brought back my son’s confidence and self-esteem which he completely lost in school. It was a blessing that I came across her, because she has changed my son’s life. I told my son’s principal that I would drive two hours to have my son work with Dr. Russo and she agreed after she saw the complete turn-around that my son had made. Dr. Russo has a connection with dyslexic children which motivates them to learn how to read. I can honestly say my son is happy and is excited to read all because of Dr. Russo‘s hard work! Dr. Russo is wonderful!!</p>
					<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
				</div>

				<div className="blurb">
					<img className="quote-top" alt="quotes" src='/quotes.png'/>
					<p>My first son, William, had trouble identifying letters and reading the simplest books. In kindergarten I was told by his teacher, "Don't worry.  Boys recognize letters later than girls." In first grade I was told by his teacher, "Don't worry.  Your son is in the range.  There are 10 other boys just like him in the class."</p>
					<p>Frustrated I brought my son, in first grade, to Dr. Concetta Russo for an evaluation.  Afterwards Dr. Russo informed me that my son was dyslexic. Her accurate diagnosis was the beginning of our journey.  Dr. Russo taught my son how to read the way he needed to learn.  She understands the brain of a dyslexic and is patience and caring.  Importantly because of Dr. Russo, William's self confidence is intact and he is now in seventh grade and doing well.  William still sees Dr. Russo so she can guide him in his writing and reading when necessary.</p>
					<p>As a far as I am concerned, Dr. Russo saved my son.  Now I do not worry!</p>
					<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
				</div>

				<div className="blurb">
					<img className="quote-top" alt="quotes" src='/quotes.png'/>
					<p>Dr. Russo to the Rescue!</p>
					<p>Dr. Russo helps me learn how to write essays and research papers. First, we brainstorm ideas that we already know about the topic. Then, she teaches me how to take bulleted notes. I take those notes and convert them into sentences and then into paragraphs. Next, we write our rough draft. She will complement me by saying, “That’s great!” and “Wonderful job! Now do the next one.” My parents always tell me that “she is like my angel and that if it wasn’t for her I would hate school.”</p>
					<p>All the strategies she ever taught me such as do your body paragraphs first and take bulleted notes and more have helped me throughout the years of my life. My reading comprehension, writing, and spelling skills have improved. I still need help, but I would be a lot worse without her help. Thanks to her I am doing well in school. When I was in first grade I hardly knew how to spell the word “cat,” but now I am able to read a 500-page book. I get 90 percent on my tests or even higher. The most important thing about her is that she is kind and determined to help me.</p>
					<p>Beside all the good and bad memories, she is probably the person that helped me the most in my whole life. I still go to her today to gain more of her knowledge and become a better learner. When I first started going to her, I thought she was mean, awful person because she made me read, write, and spell. To me she is as special and valuable as a pound of gold. Now that I am older I could see that she is just trying to help me with my reading and writing skills.
					</p>
					<img className="quote-bottom" alt="quotes" src='/quotes.png'/>
				</div>

				<main style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
					<button style={{display: !btn, fontSize: '14pt', marginBottom: '20px'}} className="blue-button" onClick={showMoreFunction}>Show Less</button>
				</main>
			</div>
		</>
		</Wrapper>
		<Boxes/>
		<PreFooter/>
		<Footer/>
		</>	
	);
};

export default RRR;
