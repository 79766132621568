import React from 'react';
import { Footer } from '../components/Footer';
import { Boxes } from '../components/Boxes';
import { PreFooter } from '../components/PreFooter';
import { Wrapper } from '../components/Wrapper';
import { TabWrapper} from '../components/TabWrapper';
import config from '../config.json';


const About = () => {
	return (
		<>
		<Wrapper backgroundColor="white" paddingButtom="40px" color="#333">
			<div style={{padding: '20px'}}>
				<h1 className="main-header">About Concetta Russo, Ed. D.</h1>
				<p style={{color: '#333'}}>
				Concetta Russo is a leading national authority on childhood developmental and learning disabilities, 
				including Dyslexia. With over 30 years experience in diagnosing 
				and treating children, Dr. Russo continues to work individually with clients in her Long Island practice 
				while instructing educators on how to recognize and treat these conditions. Her evaluations are often 
				presented to local schools boards to ensure these children receive additional attention and testing 
				time in the classroom.</p>
			</div>
			<div className='crusso-image-about'>
				<img src='/dr-concetta-russo-photo.png' height="100%" alt="Dr Russo" loading="lazy" style={{position: 'relative', bottom: '-7px'}}/>
			</div>
		</Wrapper>


		<Wrapper backgroundColor="#333" paddingBottom="30px" color="white">	
			<div style={{padding: '20px'}}>
				<h1>Education</h1>
				<p>
					Ed.D in Administration, Leadership and Technology Dowling College (2000). Dissertation “A Quasi-Experimental Study of the Effects of Fast For Word and Recipe for Reading on Central Auditory Processing and Phonological Processing Deficits Among Learning Disabled and Language Disabled Reading Students in Grades One Through Six”
				</p>
				<br/>

				<div style={{backgroundColor: '#355c71', borderRadius: '10px', padding: '10px'}}>
					<table width="100%" border="0" cellPadding="0" cellSpacing="0" className="educationtable">
						<tbody>
							<tr className="table-row">
								<td className="spacing">Advanced Certificate in Autism</td>
								<td className="spacing">2011</td>
								<td className="spacing">Mass/Lowell University</td>
							</tr>
							<tr className="table-row2">
								<td className="spacing">TESOL Certified</td>
								<td className="spacing">2008</td>
								<td className="spacing">Oxford Seminars</td>
							</tr>
							<tr className="table-row">
								<td className="spacing">Bachelor of Arts<br />Dual Certification in General Education<br />and Special Education</td>
								<td className="spacing">1978</td>
								<td className="spacing">St. Joseph College</td>
							</tr>
							<tr className="table-row2">
								<td className="spacing">Master of Science in Special Ed.</td>
								<td className="spacing">1981</td>
								<td className="spacing">C. W. Post College</td>
							</tr>
							<tr className="table-row">
								<td className="spacing">P.D. in Administration<br />SAS and SDA</td>
								<td className="spacing">1982</td>
								<td className="spacing">C. W. Post College</td>
							</tr>
							<tr className="table-row2">
								<td className="spacing">Certification in Reading</td>
								<td className="spacing">1998</td>
								<td className="spacing">State Education Dept.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Wrapper>

		<div
		style={{
			maxWidth: '964px',
			margin: '0 auto',
			padding: '0.2rem calc((100vw - 964px) / 2)',
			paddingBottom: '0px',
			backgroundImage: "url('/testimonial-banner-green.png')",
			backgroundPosition: 'right',
			backgroundSize: 'cover',
			color: '#333'
		}}
		>
			<div style={{padding: '20px'}}>
				<h2 style={
				{
					fontSize: '20pt',
					margin: "0 !important",
					fontFamily: '"Thasadith", sans-serif',
					fontWeight: "bold",
					color: "white",
					padding: "10px",
					maxWidth: "70%",
					marginBottom: '5px'
				}
				}>"Within two months of working with Dr. Russo, our daughter was reading well and more confident of herself."</h2>
			
				<a href={config.pages.RRR} style={{marginLeft: '10px', display: 'inline-block', backgroundColor: '#355c71'}}className='blue-button'>READ MORE TESTIMONINALS</a>
			</div>
		</div>


		<Wrapper backgroundColor="white" paddingBottom="0px" color="#333">
			<TabWrapper/>
		</Wrapper>

		<Boxes></Boxes>
		<PreFooter/>
		<Footer></Footer>
		</>
	);
};

export default About;

