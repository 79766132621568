import React from "react";
import { Wrapper }  from '../components/Wrapper';
import config from '../config.json';
import { Boxes } from "../components/Boxes";
import { PreFooter } from "../components/PreFooter";
import { Footer } from "../components/Footer";

const Pro = () => {
	return (
		<>
		<Wrapper backgroundColor="var(--color-white)" color="var(--color-dark)">
		<div style={{padding: '20px'}}>
				<h1>Professional Development</h1>
				<p>Call the CES office at <b style={{color: 'var(--color-teal)'}}><a href={'tel:'+config.phone}>{config.phone}</a></b> and 
				speak to Dr. Concetta Russo if you are interested in any kind of
				professional development (virtual or in person) that relates to but not limited to:</p>
			</div>
		</Wrapper>
		<Wrapper backgroundColor="var(--color-white)" color="var(--color-dark)">
			<div style={{padding: '20px'}}>
				<ol className="pro-list">
					<li>Workshops on what dyslexia is, the different types of dyslexia, the neurological background, treatments, assessments, and Orton Gillingham Reading Programs.</li>
					<li>Training in the following OG programs: 
						<ul>
							<li>Tier 1 and 2: Recipe for Reading;</li>
							<li>Tier 3: SPIRE</li>
						</ul>
					</li>
					<li>Workshops in the following subjects and strategies:
						<ul>
							<li>Auditory/Visual Processing Disorders and strategies to implement.</li>
							<li>Executive Functioning Disorders and strategies to implement.</li>
							<li>Specialized Reading strategies to implement.</li>
							<li>Specialized Spelling Strategies to implement.</li>
							<li>Assessments in Identifying Dyslexia from screening to full evaluations.</li>
						</ul>
					</li>
				</ol>
			</div>
			<img className="pro-image" src="/services-banners-03.png" alt="kids"/>
		</Wrapper>
		<Boxes/>
		<PreFooter/>
		<Footer/>
		</>
	);
};

export default Pro;
