import React from 'react';
import emailjs from '@emailjs/browser';
import config from '../../config.json';

class ContactForm extends React.Component{

    constructor(props){
      super(props);
		  this.state = {
			  showChild: !this.getCookie('contactSent')
		  };
      this.form = {};
      this.formBox = React.createRef();

      this.setSuccess = this.setSuccess.bind(this);
    }

    setSuccess = () => {
      this.closeChild();
      this.createCookieInHour('contactSent', true, 5);
      console.log('called');
    }

    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    handleForm = (name, value) => {
      this.form[name] = value;
    }

    createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
      let date = new Date();
      date.setTime(date.getTime()+(hourToExpire*60*60*1000));
      document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
    }

    sendEmail = (e) => {
      e.preventDefault();

      document.dispatchEvent(new CustomEvent("contact-info", {
        detail: {...this.form, "submitted": "success"}
      }));
      
      emailjs.sendForm(config.emailer.serviceID, config.emailer.templateID, this.formBox.current, config.emailer.publicKey)
      .then(function(results) {
        this.setSuccess();
      }.bind(this))
      .catch(function(error) {
        this.error = error;
      }.bind(this));
    }

    closeChild = () => {
		  this.setState({
			  showChild: false
      });
	  };

    openChild = () => {
      this.setState({
       showChild: true
    });
  };
	
	render() {
		if (!this.state.showChild) return (
      <>
      <h1 style={{marginTop: 0}}>Quick Contact Form</h1>
      <p>Your form has been submitted</p>
      </>
    )
		return (
      <>
      <h1 style={{marginTop: 0}}>Quick Contact Form</h1>
      <form id="contact-form" ref={this.formBox} onSubmit={this.sendEmail} style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: "row"}}>
            <input type="text" name="first_name" placeholder="Name" required onChange={(e)=>this.handleForm('first_name', e.target.value)} />
            <input type="text" name="last_name" placeholder="Last" required onChange={(e)=>this.handleForm('last_name', e.target.value)} />
          </div>
          <input type="email" name="user_email" placeholder="Email" required onKeyUpCapture={(e)=>this.handleForm('user_email', e.target.value)} />
          <textarea name="message" placeholder="Message" required onChange={(e)=>this.handleForm('message', e.target.value)}></textarea>
          <input style={{marginTop: '10px'}} className="blue-button" type="submit" value="Send"/>
      </form>
      </>
    );
  }
}

export default ContactForm;
