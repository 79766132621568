import React, { useState } from 'react';

export const Accordion = ({ data }) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <li><b>{data.title}</b>&nbsp;<span>{isActive ? '-' : '+'}</span></li>
        </div>
        {isActive && 
          <div className="accordion-content">
            {data.items.map(d => (<li key={d.id}><b>{d.title}</b>{d.line}</li>))} 
          </div>
            }
      </div>
    );
  };
export default Accordion;
  