import React from 'react';
import Tabs from './Tabs';
import { Accordion } from '../Accordion';

import accordionData from './accordion.json';

export const TabWrapper = () => {
    return (
    <div style={{padding: '10px'}}>
        <div>
            <Tabs>
                    <div label="Career Development">
                    <ul>
                    {/* <li><b>2018-Present</b> - Director of Reading for the Journey Prep School for children with dyslexia and reading disorders.</li> */}

                    <li><b>2014-Present</b> - President of the International Dyslexia Association Long Island Branch-Leading the organization in providing teacher training, parent workshops , dyslexia awareness and fundraising for the “Right to Read” Scholarship.</li>

                    <li><b>1979 to Present</b> - Crossroads for Educational Services, Inc. in Huntington (Private Practice) – Thirty-five years of teaching students with Dyslexia to read on all educational levels. Training teachers within districts in Recipe for Reading and Reading Recipe Programs for children with dyslexia. Providing evaluations to determine dyslexia or reading disorders in children and adults.</li>

                    <li><b>2013 to 2014</b> - Assistant Director of Special Education Central Islip-(1) Supervised the Preschool Department and CPSE Chairperson; (2) Section 504 Chairperson K-12(3) Supervisor of the district psychologists; (4) Supervisor of Social Workers; (5) Professional Development in Specialized Reading programs.</li>

                    <li><b>2012 to 2013</b> - Assistant Professor in the Graduate Special Education Department for The College of New Rochelle- (1) Implemented and taught Courses on all campuses in all levels of Special Education (Early Childhood and Childhood);(2)Writing a Dual Master for Special Education and TESOL(2013); Writing a Dual Master in Special Education and Secondary Education (2013); Organizing and implementing a conference on Autism (2013)</li>

                    <li><b>2010 to 2012</b> - Clinical Professor Graduate Special Education Department St. John’s University.(1) Developed Special Education Advanced Certificate- Childhood (15 credits); (2) Developed Special Education Advanced Certificate - Adolescent (15 credits);(3) Developed Special education Masters- Adolescence (36 credits); (4) Developed Certificate in Autism Studies (15 Credits); Developed Change of Career Masters in Special Education/ General Education- Adolescent; (6) Organized and implemented one conference: “Autism: Perspectives and Practice” (3/11); (7) taught and developed four online courses in Special Education Graduate Level.</li>

                    <li><b>2009 to 2012</b> - Assistant Professor and Founding Program Director for the Graduate Special Education Department for Seton Hall University in New Jersey- (1) Implemented courses online and live in all levels of Special Education (2)Developed and Implemented a 21 Credit Special Education Endorsement in Special Education (3) Developed and Implemented a 36 credit Special Education Masters with a concentration in Autism (4) Developed and Implemented a Certificate in Autism Studies (5) Developed an on-line certificate for Transition Specialist (15 credits) and (6) Organized and implemented two conferences for the University: “Response to Intervention: What Schools Need to Know”(2009) and “Autism: Perspectives and Practice for the 21 st Century” (2009).(7) Taught Graduate Special Education courses online and live.</li>

                    {/* <li><b>2010 to 2011</b> - Consultant/Staff Development for Irvington Public School District- Developing and Implementing a Model for Response to Intervention (RTI) for students in Elementary education.</li> */}

                    {/* <li><b>2009-2010 -</b> Staff Development for Girls Academy Charter School-East Village, NY- Trained and observed teachers in Orton–Gillingham Methodology Program called Recipe for Reading and Bridging the Gap.</li> */}

                    {/* <li><b>2007 to 2009</b> - Consultant/Staff Development for Jersey City Public School District- Developing and Implementing a Model for Response to Intervention (RTI) for students in Elementary and Secondary education.</li> */}
                    <li><b>2006 to 2011</b> - Consultant/Curriculum and Instruction Coordinator for School for Language and Communicate on Development Middle/High School. My responsibilities were: (1) Wrote N.Y Standard based curriculum in English, Math, Social Studies, Science, PE, Art , Music , Consumer and Family Studies,Technology 6-12 and all academics courses for Vocational Education (4) Provided guidance and instruction in the establishing of an Alternative Middle/High School ( 6-12) in Queens in September of 2006;(3) Obtained SED approval as a registered High School ;(4) Consulted and provided Staff Development in curriculum differentiation.</li>

                    <li><b>2005 to 2010</b> - Consultant for Scientific Learning Corp-My responsibilities Included (1) staff development and presentations to School districts concerning the implementation of Response to Intervention and the Neuroscience of Reading Development.</li>
                    <Accordion data={accordionData.career}/>
                    </ul>
                </div>
                <div label="Professional Experience">
                    <ul>
                    <li><b>1981 to 2007</b> Adjunct Professor in Reading, Special Education and Administration at C.W.Post,
                    College of New Rochelle, Adelphi University, St. Joseph College, NY Institute of Technology and
                    Dowling College</li>

                    <li><b>1980 to Present</b> – Staff Development in Autism, Reading, Math and Response to Intervention (RTI) for the following agencies and school districts: The N.Y. Board of Education; New York Branch of the International Dyslexia Association; Suffolk Branch of the International Dyslexia Association; N.Y. Reading Reform; Capital District Council on Dyslexia; N.J. Learning Consultants; Plainedge ; Patchogue-Medford, Bergenfield; Detroit Public Schools; Lancaster IU ; Lakewood; Lawrence; Rockville Center; Island Park; Floral Park; Jericho; South Huntington; Seaford; Deer Park; Wyandanch; South Country; Middletown; Brentwood; Amityville; Wantagh; Kings Park; Jersey 
                    City; South Bergen Jointure; Hofstra University; Irvington,NJ, Mineola, Springs, Locust Valley and Half Hollow Hills.</li>

                    <li><b>1996 to 2002</b> – Executive Director for Learning Disabilities Association for Nassau / Suffolk Counties (LDA/NS) – Conferences; Fundraising; Grant Writing; Staff Development; Workshops on Parent Advocacy</li>
                    </ul>
                </div>
                <div label="Publications">
                    <ul>
                    <li>Skeele, R.W. & Russo, C. (October 28, 2009). Students with learning difficulties: Web 2.0 resources for Response to Intervention (RTI). AACE World Conference on E-Learning in Corporate, Government, Healthcare, & Higher Education, Vancouver, BC, Canada.</li>

                    <li>Skeele, R.W. & Russo, C. (October, 2010). The Internet, Online Safety, and Youth with Asperger’s Syndrome.One Voice Conference and Forum, New York</li>

                    <li>Skeele, R.W. & Russo, C. (October, 2010). Emerging and Evolving Technologies: Support and Independence for Students with Asperger’s.</li>

                    <li>Skeele,R.W. & Russo,C. (May, 2012). Turning a Learning Disability into a Learning Difference. Transitioning the LD Student from High School to College. EdMedia,2012:World Conference on Educational Media & Technology, Denver. Colorado.</li>

                    <li>Skeele, R.W. & Russo, C. (May 2012).Coherent Compliance in Transition Planning Role of InformationLiteracy and Emerging Technologies to Sustain and Complete a Postsecondary Degree. 12th Annual Second City International Conference on Disability Studies in Education:”Contemplating Disability Studies in Education Throughout Life: In School & Beyond:, Hunter College, N.Y.</li>

                    <li>Russo,C. (2009). 30 Hour Online Course on Response to Intervention.</li>

                    <li>Knowledge  Delivery Systems</li>

                    <li>Russo, C. (2009). RTI Guide: Making It Work” Elementary Edition.</li>

                    <li>National Professional Resources, Inc.</li>

                    <li>Russo, C. (2006). Blueprint for Reading-Kindergarden.  Educators Publishing Service</li>

                    <li>Russo, C. (2006).  White Paper : Recipe for Reading. Educators Publishing Service.</li>

                    <li>Russo, C. (2003). Bridging the Gap: an Orton-Gillingham Reading Manual 4-12. RusCons Publishing Co.</li>

                    <li>Russo, C. (1982). Recipe for Reading Workbooks. Educators Publishing Service, Inc</li>

                    <li>Russo, C. (1980).  Recipe for Math.  Senior Editor ,  Book Lab Co.</li>
                    </ul>
                </div>
                <div label="Presentations">
                    <ul>
                    <h2 style={{color: 'var(--color-blue)'}}>Conference Presentations:</h2>
                    <Accordion data={accordionData['section-recent']}/>
                    <h2 style={{color: 'var(--color-blue)'}}>Presentations to School Districts:</h2>
                    <Accordion data={accordionData['section-2008']}/>
                    <Accordion data={accordionData['section-2007']}/>
                    <Accordion data={accordionData['section-2006']}/>
                    <Accordion data={accordionData['section-pre-2006']}/>
                    </ul>
                </div>
                <div label="Scholarship">
                    <ul>
                    <b><a href="https://www.NPRinc.com">www.NPRinc.com</a></b> <i>(RTI Guide: Making It Work)</i><br/>
					<b><a href="https://www.epsbooks.com">www.epsbooks.com</a></b> <i>(Recipe for Reading manual and workbooks)</i>
                    </ul>
                </div>
            </Tabs>
        </div>
    </div>
    )
}