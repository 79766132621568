import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const fadeImages = [
  {
    url: '/slide1.jpg',
    caption: "Within two months of working with Dr. Russo, our daughter was reading well and more confident of herself."
  },
  {
    url: '/slide2.jpg',
    caption: "My heart was broken until we met Dr. Russo. My son is now asking me to buy him books."
  },
  {
    url: '/slide3.jpg',
    caption: "Dr. Russo has a ‘gift’ Our child is reading 100% better. She is a blessing to our entire family."
  },
  {
    url: '/slide4.jpg',
    caption: "She understands the brain of a dyslexic and is patient and caring. Dr. Russo saved my son. Now I do not worry!"
  },
];


export const SlideshowSingle = () => {
  return (
    <div className="slide-container small">
      <Fade>
        {fadeImages.map((fadeImage, index) => (
          <div key={index} style={{display: "flex", justifyContent: "center"}}>
            <img src={fadeImage.url} style={{margin: "0 auto"}} alt="slider" />
            <h2 style={
            {
              fontSize: '24pt',
              fontFamily: '"Thasadith", sans-serif',
              fontWeight: "bold",
              color: "#3e7198",
              position: "absolute",
              padding: "20px",
              bottom: 0,
              margin: 0,
              backgroundColor: "#ffffff9e"
            }
        }>"{fadeImage.caption}"</h2>
          </div>
        ))}
      </Fade>
    </div>
  )
}