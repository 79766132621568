import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages';
import About from "./pages/about";
import CES from "./pages/ces";
import RRR from "./pages/rrr";
import Contact from "./pages/contact";
import Pro from "./pages/pro";
import Quiz from "./pages/quiz";
import Assessments from "./pages/assessments";
import NotFound from "./pages/404";

function App() {
	return (
		<Router>
			<Navbar/>
			<Routes>
				<Route path="/" element={<Home/>} />
				<Route path="/about" element={<About/>} />
				<Route path="/ces"element={<CES/>} />
				<Route path="/rrr"element={<RRR/>} />
				<Route path="/contact"element={<Contact/>} />
				<Route path="/assessments"element={<Assessments/>} />
				<Route path="/pro"element={<Pro/>} />
				<Route path="/quiz"element={<Quiz/>} />
				<Route path="*"element={<NotFound/>} />
			</Routes>
		</Router>
	);
}
export default App;
