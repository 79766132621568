import { React, useState } from "react";
import { Wrapper } from '../components/Wrapper';
import Iframe from 'react-iframe';
import config from '../config.json';
import { Boxes } from '../components/Boxes';
import { Footer } from "../components/Footer";
import { PreFooter } from "../components/PreFooter";
import ContactForm from "../components/ContactForm";
import ModalWrapper from '../components/Modal';


const Contact = () => {

	const [loader, setLoader] = useState('flex');
	const [iFrame, setIFrame] = useState('none');

	const [fullName, setFullName] = useState();

    document.addEventListener("contact-info", function(event) {
		setFullName(event.detail.first_name + ' ' + event.detail.last_name);
		window.modal.openChild();
	});

	return (
		<>
		<div style={{width: 'vw', overflowX: 'hidden'}}>
			<div className="lds-ring" style={{display: loader}}>
				<div></div><div></div><div></div><div></div>
			</div>
			<div style={{backgroundColor: '#222324'}}>
			<Iframe 
				url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.1889454338416!2d-73.3456051!3d40.7798601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e82c2041d6b6a5%3A0x5ba380de1bd99f3f!2s323%20Concord%20St%2C%20Dix%20Hills%2C%20NY%2011746!5e0!3m2!1sen!2sus!4v1699764873679!5m2!1sen!2sus"
				width="100%"
				height="500px"
				display={iFrame}
				backgroundColor="#222324"
				position="relative"
				frameBorder="0"
				onLoad={() => {setLoader('none'); setIFrame('flex')}}
				styles={{filter: 'invert(90%) hue-rotate(180deg)', backgroundColor: 'black'}}
			/>
		</div>

			
			<Wrapper alignItems="start" className="contact-info" backgroundColor="var(--color-white)" paddingBottom="10px" color="var(--color-dark)">
				<div style= {{padding: "20px 20px 0 20px", width: '100%'}}>
					<h1 style={{marginTop: 0}}>Contact Crossroads</h1>
					<ul className="office-info">
						<li><b>Office: Mobile:</b> <a href={"tel:"+config.phone}>{config.phone}</a></li>
						<li><b>Fax:</b> <a href={"fax:"+config.fax}>{config.fax}</a></li>
						<li><b>Email:</b> <a href={"mailto:"+config.email}>{config.email}</a></li>
						<br/>
						<h3>Address</h3>
						<li><b>{config.address.street}</b></li>
						<li><b>{config.address.city}, {config.address.state} {config.address.zip}</b></li>
					</ul>
				</div>
				<div style= {{padding: "20px 20px 0 20px", width: '100%'}}>
					<ContactForm/>
					<ModalWrapper>
						<h1>Contact Form Submitted</h1>
						<p>Dear {fullName},</p>
							<p>
							Thank you for writing to us. We got your request and within a few business days, we will get in touch. 
							Meanwhile, take a look at our services.</p>
							<p>Best regards,<br/>{config.fullName}</p>
					</ModalWrapper>
				</div>
			</Wrapper>
			</div>
			<Boxes></Boxes>
			<PreFooter/>
			<Footer></Footer>
		</>
	);
};

export default Contact;
