// components/Navbar/navbarElements.js

import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
	height: auto;
	position: absolute;
	right: 0;
	justify-content: space-between;
	flex-direction: columns;
    width: 65vw;
	background: #333;
    margin: 0 auto;
	z-index: 12;
	justify-content: flex-start;

`;

export const NavMenu = styled.div`
	display: block;
	align-items: center;
    border-bottom: 2px solid var(--color-light-gray);
	border-left: 2px solid var(--color-light-gray);
	@media screen and (min-width: 964px) {
		display: none
	}
`;

export const NavLink = styled(Link)`
	color: #ddd;
	display: flex;
	font-size: 14pt;
	align-items: center;
	text-decoration: none;
	padding: 20px 0;
    font-weight: bold;
	cursor: pointer;
	&.active {
		color: #58BEBF;
	}
	&:last-of-type {
		border-bottom: none
	}
	@media screen and (max-width: 768px) {
		font-size: 10pt;
	}
	@media screen and (max-width: 964px) {
		border-bottom: 1px solid rgb(72, 72, 72);
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: rgb(72, 72, 72);
		margin: 0px 10px;
	}
`;

export const Bars = styled(FaBars)`
	display: none;
	color: white;
	@media screen and (max-width: 964px) {
		display: flex;
		
		margin-right: 20px;
		// transform: translate(100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;
	/* Third Nav */
	/* justify-content: flex-end;
	width: 100vw; */
	@media screen and (max-width: 964px) {
		display: none;
	}
`;

export const NavBtnLink = styled(Link)`
	border-radius: 4px;
	background: #808080;
	padding: 10px 22px;
	color: #000000;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	/* Second Nav */
	margin-left: 24px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #808080;
	}
    @media screen and (max-width: 964px) {
		display: none;
	}
`;
