import React from 'react';

export const Wrapper = (props) => {
	let classname = !props.className ? '' : props.className; 
	let align = !props.alignItems ? 'end' : props.alignItems;
	return (
		<>
		<div className={classname} style={{
			display: 'flex',
			alignItems: align,
			margin: '0 auto',
			justifyContent: 'space-between',
			padding: '0.2rem calc((100vw - 964px) / 2)',
			paddingBottom: props.paddingBottom,
			backgroundColor: props.backgroundColor,
			color: props.color,
			borderBottom: '4px solid var(--color-teal)'
		}}
		>
			{props.children}
		</div>
		</>
	)
}