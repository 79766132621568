import { React } from "react";

export const Boxes = () => {
	
  return (
    <div>
		<div className='section-wrapper' style={{maxWidth: '964px', margin: '0 auto'}}>
			<div className='section'>
				<h3>Assessments</h3>
				Our standardized battery of tests can pinpoint if your child has dyslexia, what kind of dyslexia they have and the impact their dyslexia has on their academic profile.
			</div>
			<div className='section'>
				<h3>Consultation and Professional Development</h3>
				Crossroads presents Orton-Gillingham based reading programs that develop the underpinnings of reading, spelling, fluency, comprehension and auditory memory for students within a K-12 environment.
			</div>
			<div className='section'>
				<h3>Academic Therapy</h3>
				Dyslexia Crossroads provides intensive, one-to-one instruction that focuses
				on developing Phonemic Awareness, Phonics, Fluency, and vocabulary skills which lead to better
				comprehension skills. Academic Therapy is offered live or virtual.
			</div>
		</div>
	</div>
  )
}