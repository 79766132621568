
import React from 'react';
import { Nav, NavLink, NavMenu } from "./navbarElements";
import config from '../../config.json';


class NavbarMobile extends React.Component{

    constructor(props){
        super(props);
		this.state = {
			showChild: false
		};
    }
	
	getSnapshotBeforeUpdate(prevProps) {
		return { notifyRequired: prevProps.display !== this.props.display };
	}
	    
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot.notifyRequired) {
		  this.updateAndNotify();
		}
	  }

	updateAndNotify = () => {
		if (this.props.display) {
			this.openChild();
		} else {
			this.closeChild();
		}
	  }

    closeChild = () => {
		this.setState({
			showChild: false
		});
		this.setDispatch();
	};

	setDispatch = () => {
		const event = new Event('selected', {
			details: true
		});
		document.dispatchEvent(event);
	}

	openChild = () => {
		this.setState({
			showChild: true
		});
	};
	
	render() {
		if (!this.state.showChild) return;
		return (
			<>
				<Nav>
					<NavMenu>
							<NavLink onClick={() => this.closeChild()} to={config.pages.Home}>
								Home
							</NavLink>
							<NavLink onClick={() => this.closeChild()} to={config.pages["About Dr. Russo"]}>
								About Dr. Russo
							</NavLink>
							<NavLink onClick={() => this.closeChild()} to={config.pages.CES}>
								CES
							</NavLink>
							<NavLink onClick={() => this.closeChild()} to={config.pages.Assessments}>
								Assessments
							</NavLink>
							<NavLink onClick={() => this.closeChild()} to={config.pages.RRR}>
								RRR
							</NavLink>
							<NavLink onClick={() => this.closeChild()} to={config.pages.Contact}>
								Contact					
							</NavLink>
							<NavLink  onClick={() => this.closeChild()} to={config.pages["Professional Development"]}>
								Professional Development
							</NavLink>
					</NavMenu>
				</Nav>
			</>
		);
	}
}

export default NavbarMobile;
