import React from 'react';
import config from '../../config.json';


export const PreFooter = () => {
	return (
		<>
			<div className='footer'>
				<div className='footer-wrapper'>
					<div className='footer-image'>
						<img src='/dr-concetta-russo-photo.png' alt="Dr Russo" loading="lazy"/>
					</div>
					<div className='footer-content'>
						<div className='footer-blog'>
							<h3>Concetta Russo, Ed. D.</h3>
							<p style={{fontSize: '12pt'}}>
							is a leading national authority on childhood developmental and learning
							disabilities, Including Dyslexia, Dysgraphia and Dyscalculia. With over 35 years’ experience in
							diagnosing and treating children, Dr. Russo continues to work individually with clients in her
							Long Island practice while instructing educators on how to recognize and treat these conditions.
							Her evaluations are presented to local schools’ Committee of Special Education to ensure these
							children receive additional specialized reading services.</p>
							<p><a className='dark-button' href="/about">LEARN MORE</a></p>
						</div>
						<div className='footer-buttons'>
							<p><a className='dark-button' href={config.pages.Quiz}><small>Does Your Child Have</small><br/><b>DYSLEXIA?</b></a></p>
							<p><a className='dark-button' href={config.pages.Assessments}><small>Assessment for</small><br/><b>DYSLEXIA</b></a></p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}