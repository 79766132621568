import { React, useState } from "react";
import { Nav, NavLink, Bars, NavMenu } from "./navbarElements";
import logo from './logo.svg';
import NavbarMobile from "../NavbarMobile";
import config from '../../config.json';

const Navbar = () => {
	const [open, setOpen] = useState(false);

	const openNav = () => {
		setOpen(open => open = !open);
	};

	const update = () => {
		setOpen(false);
	}

	document.addEventListener('selected', (e => {
		update();
	}
	));
	
	return (
		<>
			<Nav>
				<NavMenu>
						<a href='/'><img src={logo} className="logo" alt="logo"/></a>
						<NavLink to={config.pages.Home} >
							Home
						</NavLink>
						<NavLink to={config.pages["About Dr. Russo"]}>
							About Dr. Russo
						</NavLink>
						<NavLink to={config.pages.CES}>
							CES
						</NavLink>
						<NavLink to={config.pages.Assessments}>
							Assessments
						</NavLink>
						<NavLink to={config.pages.RRR}>
							RRR
						</NavLink>
						<NavLink to={config.pages.Contact}>
							Contact					
						</NavLink>
						<NavLink to={config.pages["Professional Development"]}>
							Professional Development
						</NavLink>
                    <Bars onClick={openNav}/>
				</NavMenu>
			</Nav>
			<NavbarMobile display={open}/>
		</>
	);
};

export default Navbar;
