import React from 'react';
import { Wrapper } from '../components/Wrapper';
import { Boxes } from '../components/Boxes';
import { PreFooter } from '../components/PreFooter';
import { Footer } from '../components/Footer';
import config from '../config.json';

const NotFound = () => {
	return (
        <>
        <Wrapper backgroundColor='var(--color-white)' color='var(--color-dark)' paddingBottom='20px'>
            <div className='not-found'>
                <h1 style={{fontSize: '5rem', margin: 0}}>404</h1>
                <h1 style={{margin: 0, marginBottom: 30}}>Page Not Found</h1>
                <p>Sorry, we couldn't find that page. Please visit the <a href={config.pages.Home}>Homepage</a> to checkout our content.</p>
            </div>
        </Wrapper>
        <PreFooter/>
        <Footer/>
        </>
    )
}

export default NotFound;