import React from 'react';
import { Slideshow } from '../components/Slider';
import { SlideshowSingle } from '../components/SliderSingle';
import { Footer } from '../components/Footer';
import { Boxes } from '../components/Boxes';
import { PreFooter } from '../components/PreFooter';

const Home = () => {
	return (
		<>
		<div
		style={{
			justifyContent: 'center',
			alignItems: 'center',
			margin: '0 auto',
			padding: '0.2rem calc((100vw - 964px) / 2)',
			paddingBottom: 0,
			backgroundColor: 'white',
			borderBottom: '4px solid var(--color-teal)'
		}}
		>	
			<Slideshow></Slideshow>
			<SlideshowSingle></SlideshowSingle>
		</div>
		
		<Boxes></Boxes>

		<PreFooter/>

		<Footer></Footer>
		</>
	);
};

export default Home;
