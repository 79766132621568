import React from "react";
import { Wrapper }  from '../components/Wrapper';
import { Boxes } from "../components/Boxes";
import { PreFooter } from "../components/PreFooter";
import { Footer } from "../components/Footer";
import ModalWrapper from "../components/Modal";
import config from '../config.json';


const Quiz = () => {

	return (
		<>
		<Wrapper backgroundColor="var(--color-white)" color="var(--color-dark)">
			<div style={{padding: '20px'}}>
				<h1>Does your child display one or more of these symptoms</h1>
				<div className="quiz-form" style={{display: 'flex', flexDirection: 'column'}}>
                    <ul>
                        <li><input type="checkbox"/> Has an average to high IQ but still struggles with school work?</li>
                        <li><input type="checkbox"/> Loses concentration or sense of time easily?</li>
                        <li><input type="checkbox"/> Expresses confusion towards the alphabet and sentence structures?</li>
                        <li><input type="checkbox"/> Has difficulties learning to speak?</li>
                        <li><input type="checkbox"/> Shows difficulty in verbally completing sentences or putting words to thoughts?</li>
                        <li><input type="checkbox"/> Struggles with mathematic or word problems?</li>
                        <li><input type="checkbox"/> Has trouble memorizing number facts?</li>
                        <li><input type="checkbox"/> Acts out frequently in school and social settings?</li>
                        <li><input type="checkbox"/> Does your child appear to see things backwards/upside down?</li>
                        <li><input type="checkbox"/> Does your child have difficulty spelling?</li>
                        <li><input type="submit" value="Find Out Now" name="submit" onClick={() => window.modal.openChild()} className="blue-button"></input></li>
                    </ul>
                </div>
                <ModalWrapper>
                    <h1>Quiz Results</h1>
                    <p>If one or more of these conditions affect your child, Crossroads may be of help. Concetta Russo, Ed. D. 
                        is a leading authority on childhood developmental and learning disabilities, including Dyslexia, Autism
                        and Attention Deficit Disorder. With over 30 years experience in diagnosing and treating children, Dr. Russo
                        works individually with clients in her Long Island practice, while continuing to instruct educators how to
                        recognize and treat these conditions. Her evaluations are often presented to local schools boards to ensure
                        these children receive additional attention and testing time in the classroom.</p>
					<a className='blue-button' href={config.pages.CES} style={{marginRight: '20px'}}>Learn More</a>
                    <a className='blue-button' href={config.pages.Contact}>Contact Us</a>
                </ModalWrapper>
			</div>
		</Wrapper>
		<Boxes/>
		<PreFooter/>
		<Footer/>
		</>
	);
};

export default Quiz;
