// Navbar Styles

import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
	height: 85px;
	display: flex;
    width: 100%;
    margin: 0 auto;
	z-index: 12;
	justify-content: space-between;
`;

export const NavLink = styled(Link)`
	color: #ddd;
	display: flex;
	font-size: 10pt;
	align-items: center;
	text-decoration: none;
	height: 100%;
    font-weight: bold;
	cursor: pointer;
	padding: 0 10px;
	&.active {
		color: #58BEBF;
	}
	@media screen and (max-width: 964px) {
		display: none;
	}
`;

export const Bars = styled(FaBars)`
	display: none;
	color: white;
	@media screen and (max-width: 964px) {
		display: flex;
		
		margin-right: 20px;
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
    border-bottom: 2px solid #6a666a;
    justify-content: flex-start;
	padding: 0.2rem calc((100vw - 964px) / 2);
	width: 100vw;
    white-space: nowrap;
    @media screen and (max-width: 964px) {
		justify-content: space-between;
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;
	@media screen and (max-width: 964px) {
		display: none;
	}
`;

export const NavBtnLink = styled(Link)`
	border-radius: 4px;
	background: #808080;
	padding: 10px 22px;
	color: #000000;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	margin-left: 24px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #808080;
	}
    @media screen and (max-width: 964px) {
		display: none;
	}
`;
