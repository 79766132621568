import React from 'react';


const Closer = (props) => <div>
    <button onClick={props.onClose}>X</button>
</div>;

class Modal extends React.Component{

    constructor(props){
        super(props);
		this.state = {
			showChild: false
		};
        window.modal = this;
    }

    closeChild = () => {
		this.setState({
			showChild: false
		});
	};

	openChild = () => {
		this.setState({
			showChild: true
		});
	};
	
	render() {
		if (!this.state.showChild) return;
		return (
		<>
			<div className='modal-wrapper'>
				<div className='modal'>
					<Closer onClose={this.closeChild} />
					{this.props.children}
				</div>
			</div>
		</>
		)
	}
}

export default Modal;
