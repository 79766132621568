import React from "react";
import { Wrapper } from "../components/Wrapper";
import { Boxes } from "../components/Boxes";
import { Footer } from "../components/Footer";
import { PreFooter } from "../components/PreFooter";

const imgs = [
	'/services-banners-01.png',
	'/services-banners-02.png',
	'/services-banners-03.png',
	'/services-banners-04.png'
];

function randomImage() {
	return imgs[Math.floor(Math.random() * imgs.length)];
}

const Assessments = () => {
	return (
		<>
			<Wrapper backgroundColor="white" paddingBottom="0px" color="#333">
				<div style={{padding: '20px', paddingBottom: 0}}>
					<h1>Dyslexia Crossroads Program</h1>
					<p style={{color: '#333'}}>
					Dyslexia is a specific learning disability that is neurological in origin. It is
					characterized by difficulties with accurate and/or fluent word recognition and by
					poor spelling and decoding abilities. These difficulties typically result from a
					deficit in the phonological component of language that is often unexpected in
					relation to other cognitive abilities and the provision of effective classroom
					instruction. Secondary consequences may include problems in reading
					comprehension and reduced reading experience that can impede the growth of
					vocabulary and background knowledge.</p>

					<p style={{color: '#333'}}>
					In addition, according to research, the major cognitive correlates of dyslexia
					include weaknesses in one or more of the following abilities: phonological
					awareness, orthographic awareness, memory, rapid automatized naming, and
					processing speed.</p>
					<br/>
				</div>
				<div style={{height: '100%', flex: '0 0 auto'}}>
					<img className="ces-banner-image" src={randomImage()} alt="service banner"/>
				</div>
			</Wrapper>

			<div style={{backgroundColor: 'var(--color-blue)'}}>
				<center><h1 style={{margin: 0, padding: 20, borderBottom: '4px solid var(--color-teal)'}}>Why does my child struggle in reading in school?</h1></center>
			</div>
			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>Could my child have some form of dyslexia?</h2>
					<p>
					Crossroads for Educational Services has three standardized batteries of tests that can
					pinpoint if your child has dyslexia, what kind of dyslexia or does your child has a reading
					disorder. Dyslexia has an impact on a child's academic profile. The choice of
					remediation treatment is important, and the kind of Orton Gillingham intervention
					program needs to be chosen carefully. Crossroads works very closely with the family as
					well as their district. We are not adversarial, and we try to work cooperatively with all
					parties concerned.</p>
				</div>
			</Wrapper>

			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>Crossroads have three types of assessments. They are the following</h2>
					<ol className="primary-reading">
						<li><b>Dyslexia Screener</b> includes a form called the Dyslexia Profile that parents fill
						out. This includes questions in the following areas: Primary reading and spelling
						difficulties; Secondary reading and writing difficulties; Cognitive and linguistic
						abilities; Ability to learn when reading is not required; Family at- risk indicators
						and possible characteristics of dyslexia. If the child has had previous testing
						results those scores are included. (1 hour)</li>

						<li><b>Educational Reading Evaluation</b> that includes standardized tests in
						decoding, encoding, reading, listening comprehension and written language. (1 to 2 hours)</li>

						<li><b>Specialized Comprehensive Dyslexia Reading Evaluation</b> that includes
						standardized tests in Intelligence Quotient; Phonological Awareness;
						Phonological Processing; Visual processing; Auditory Processing; Memory;
						Decoding; Encoding; Reading Listening comprehension and Written language. (3
						to 4 hours)
						<br/><br/>
						<b>*Inquire about Crossroads Spanish version of the Dyslexia assessment.</b>
						</li>
					</ol>
				</div>
			</Wrapper>

			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>Crossroads also have an additional two types of
						assessments that reflect Dyscalculia and Dysgraphia. What does this mean?</h2>

					<ol className="primary-reading">
						<li>Dysgraphia is a specific learning disability that affects how easily children acquire
						written language and how well they use written language to express their
						thoughts. We offer:
							<ul className="secondary-reading">
								<li><b>a. Dysgraphia Screener</b></li>
								<li><b>b. Specialized Comprehensive Writing Assessment</b></li>
							</ul>
						</li>

						<li>Dyscalculia is a specific disability resulting in difficulty learning or comprehending
						arithmetic, such as difficulty in understanding numbers, learning how to
						manipulate numbers, performing mathematical calculations, and learning facts in
						mathematics. We offer:
							<ul className="secondary-reading">
								<li><b>a. Dyscalculia Screener</b></li>
								<li><b>b. Specialized Comprehensive Math Assessment</b></li>
							</ul>
						</li>
					</ol>
				</div>
			</Wrapper>
			
			<Wrapper className="dcp-info" backgroundColor="var(--color-white)" paddingBottom="0px" color="var(--color-dark)" alignItems="start">
				<div>
					<h2>How Do We Use Testing Results?</h2>
					<p>After a student's assessment, I meet with the parents to explain what the score within
					the battery means and what impact those scores have on a student's educational
					profile. We provide you with a written report showing your child's test results and what
					recommendations we suggest. Because we use standardized tests you can present this
					report to your school district, and I personally present your report at your CSE meeting.
					You are never alone every step of the way. As I mentioned, we are not adversarial, so
					we work as partners with you and your school district.</p>
				</div>
			</Wrapper>
			<Boxes></Boxes>
			<PreFooter/>
			<Footer></Footer>
		</>
	);
};

export default Assessments;
